import { Inject, Injectable } from '@angular/core';
import {
    ContainerPlacingTaskListItem,
    FlashMessageService,
    GEOLOCATION_SERVICE_IMPL,
    IGeolocationService,
    ITasksService,
    LatLng,
    MultiPickingTask,
    TaskListItem,
    TASKS_SERVICE_IMPL,
    UserRole,
} from 'shared';
import { AUTH_SERVICE_IMPL, IAuthenticationService } from 'shared';
import { BehaviorSubject } from 'rxjs';
import { CollectingTaskListItem } from 'shared';
import { DeliveringTaskListItem } from 'shared';
import { ReturningTaskListItem } from 'shared';
import { RefundingTaskListItem } from 'shared';
import { FlashMessage } from 'dm-src/dtos/flash-message';
import { ColorClass } from 'shared';
import { TaskFailedModalService } from 'dm-src/app/modules/shared/task-failed-modal/task-failed-modal.service';
import { TaskGroup } from 'dm-src/types/task-group';
import { TaskGroupItem } from 'dm-src/types/task-group-item';
import { TaskGroupItemType } from 'dm-src/types/task-group-item-type';
import { ModalService } from 'shared';
import moment from 'moment';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { SuppliersService } from 'dm-src/services/suppliers/suppliers-service';
import { SupplierWorktimeActivity } from 'dm-src/models/supplier-worktime-activity';
import { HttpResponse } from '@angular/common/http';
import { SUPPLIERS_SERVICE_IMPL } from 'dm-src/services/suppliers/isuppliers-service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MyTasksService {
    private _hasMessage: BehaviorSubject<boolean>;
    private _flashMessage: BehaviorSubject<FlashMessage>;
    private _taskGroups: BehaviorSubject<TaskGroup[]>;
    private _todayTaskGroups: BehaviorSubject<TaskGroup[]>;
    private _selectedTask: BehaviorSubject<TaskListItem>;
    private _isNativeApplicationWrapper: BehaviorSubject<boolean>;
    private _isNativeAppTrackingStarted: BehaviorSubject<boolean>;
    private _isNextTaskFindingInProgress: BehaviorSubject<boolean>;
    private _isGettingTasksInProgress: BehaviorSubject<boolean>;
    private _doesGeoTrackingNeeded: boolean;
    private _supplierWorktimeActivity: SupplierWorktimeActivity =
        new SupplierWorktimeActivity();
    private _maximumMultipickingTasks: BehaviorSubject<number>;
    private _selectedMultipickingTasks: BehaviorSubject<MultiPickingTask[]>;
    private _multiPickingAlreadyInProgress: BehaviorSubject<boolean>;

    public get taskGroups() {
        return this._taskGroups.asObservable();
    }

    public get todayTaskGroups() {
        return this._todayTaskGroups.asObservable();
    }

    public get flashMessage() {
        return this._flashMessage.asObservable();
    }

    public get hasMessage() {
        return this._hasMessage.asObservable();
    }

    public get selectedTask() {
        return this._selectedTask.asObservable();
    }

    public get isNativeApplicationWrapper() {
        return this._isNativeApplicationWrapper.asObservable();
    }

    public get isNativeAppTrackingStarted() {
        return this._isNativeAppTrackingStarted.asObservable();
    }

    public get isNextTaskFindingInProgress() {
        return this._isNextTaskFindingInProgress.asObservable();
    }

    public get isGettingTasksInProgress() {
        return this._isGettingTasksInProgress.asObservable();
    }

    public get maximumMultipickingTasks() {
        return this._maximumMultipickingTasks.asObservable();
    }

    public get selectedMultipickingTasks() {
        return this._selectedMultipickingTasks.asObservable();
    }

    public get multiPickingAlreadyInProgress() {
        return this._multiPickingAlreadyInProgress.asObservable();
    }

    constructor(
        @Inject(AUTH_SERVICE_IMPL) private _authService: IAuthenticationService,
        @Inject(TASKS_SERVICE_IMPL) private _tasksService: ITasksService,
        @Inject(SUPPLIERS_SERVICE_IMPL) private _suppliersService: SuppliersService,
        @Inject(GEOLOCATION_SERVICE_IMPL)
        private _geolocationService: IGeolocationService,
        public modalService: ModalService,
        private _flashMessageService: FlashMessageService,
        private _taskFailedModalService: TaskFailedModalService,
        private _isWrappingService: IsWrappingService,
        private _router: Router,
        private _IosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {
        this._taskGroups = new BehaviorSubject<TaskGroup[]>([]);
        this._todayTaskGroups = new BehaviorSubject<TaskGroup[]>([]);
        this._flashMessage = new BehaviorSubject<FlashMessage>(null);
        this._hasMessage = new BehaviorSubject<boolean>(false);
        this._selectedTask = new BehaviorSubject<TaskListItem>(null);
        this._doesGeoTrackingNeeded = this._authService.currentUser.hasRoles([
            UserRole.Deliverer,
        ]);
        this._isNextTaskFindingInProgress = new BehaviorSubject<boolean>(false);
        this._isNativeAppTrackingStarted = new BehaviorSubject<boolean>(false);
        this._maximumMultipickingTasks = new BehaviorSubject<number>(0);
        this._selectedMultipickingTasks = new BehaviorSubject<MultiPickingTask[]>([]);
        this._isGettingTasksInProgress = new BehaviorSubject<boolean>(false);
        this._multiPickingAlreadyInProgress = new BehaviorSubject<boolean>(false);

        if (this._isWrappingService.isWrapping()) {
            this._isNativeApplicationWrapper = new BehaviorSubject<boolean>(true);
        } else {
            this._isNativeApplicationWrapper = new BehaviorSubject<boolean>(false);
        }
    }

    startAppGPSTracking() {
        this._isNativeAppTrackingStarted.next(true);
        this.setWorkTimeActivity(true);

        if (window.ReactNativeWebView) {
            this._IosAndAndroidNatvieFunctionsService.latLonStart({
                userId: this._authService.currentUser?.userID,
                email: this._authService.currentUser?.email,
            });
        } else {
            if (
                !window.location.origin.includes('localhost') &&
                this._doesGeoTrackingNeeded &&
                this._isNativeApplicationWrapper.value
            ) {
                window.location.href =
                    window.location.origin +
                    '/roksh:location:start/' +
                    this._authService.currentUser.userID;
            }
        }

        this.getNextTask();
        this.modalService.setModalVisibility(false, 'start-worktime-confirm');
    }

    stopAppGPSTracking() {
        this._isNativeAppTrackingStarted.next(false);
        this.setWorkTimeActivity(false);
        if (window.ReactNativeWebView) {
            this._IosAndAndroidNatvieFunctionsService.latLonStop();
        } else {
            if (
                !window.location.origin.includes('localhost') &&
                this._doesGeoTrackingNeeded &&
                this._isNativeApplicationWrapper.value
            ) {
                window.location.href =
                    window.location.origin +
                    '/roksh:location:stop/' +
                    this._authService.currentUser.userID;
            }
        }
    }

    public getMyTasks(refreshMyTasks: boolean = false): void {
        this._isGettingTasksInProgress = new BehaviorSubject<boolean>(true);
        this._tasksService
            .getMyTasks(this._authService.currentUser.userID)
            .subscribe((response) => {
                this._taskGroups.next(
                    this.generateTaskGroups(
                        response.collectingTasks,
                        response.deliveringTasks,
                        response.returningTasks,
                        response.refundingTasks,
                        response.containerPlacingTasks
                    )
                );

                if (!refreshMyTasks) {
                    this._isNativeAppTrackingStarted.next(
                        response.supplierWorktimeIsActive
                    );
                }

                if (response.maximumMultipickingTasks > 1) {
                    this._maximumMultipickingTasks.next(
                        response.maximumMultipickingTasks
                    );

                    var selectedMultipickingTasks = [];

                    response.collectingTasks?.forEach((task) => {
                        if (task.multipickingTaskIdentifierChar) {
                            let multiPickingTask = {
                                deliveryTaskID: task.deliveryTaskID,
                                orderID: task.orderID,
                                providerName: task.providerName,
                                multipickingTaskIdentifierChar:
                                    task.multipickingTaskIdentifierChar,
                                multipickingTaskIdentifierColor:
                                    task.multipickingTaskIdentifierColor,
                            };

                            selectedMultipickingTasks.push(multiPickingTask);
                            this._selectedMultipickingTasks.next(
                                selectedMultipickingTasks
                            );
                            this._multiPickingAlreadyInProgress.next(true);
                        }
                    });
                }

                if (this._taskGroups.value.length > 0) {
                    var todayTaskGroup = this.getTodayTasks(this._taskGroups.value);
                    this._todayTaskGroups.next(todayTaskGroup); //Egyelőre nincs aznapi szűrés
                } else {
                    this._todayTaskGroups.next([]);
                }
                this._isGettingTasksInProgress = new BehaviorSubject<boolean>(false);
            });
    }

    startMultipicking() {
        if (this._selectedMultipickingTasks.value?.length < 2) return;

        this._tasksService
            .startMultiPicking(
                this._selectedMultipickingTasks.value.map((x) => x.orderID)
            )
            .subscribe((_) => {
                let collectingsQueryParam = '';
                this._selectedMultipickingTasks?.value.forEach((task) => {
                    collectingsQueryParam +=
                        task.deliveryTaskID + '#' + task.orderID + ',';
                });

                // this._router.navigate(['multi-collecting'], {
                //     queryParams: { collectings: collectingsQueryParam },
                // });
                const url = `multi-collecting?collectings=${encodeURIComponent(
                    collectingsQueryParam
                )}`;
                window.location.href = url;
            });
    }

    public selectMultipickingTask(multiPickingTask: MultiPickingTask) {
        var selectedMultipickingTasks = this._selectedMultipickingTasks.value;
        if (!selectedMultipickingTasks) {
            selectedMultipickingTasks = [];
        }

        selectedMultipickingTasks.push(multiPickingTask);
        this._selectedMultipickingTasks.next(selectedMultipickingTasks);
    }

    public deselectMultipickingTask(maskGroups: MultiPickingTask) {
        var selectedMultipickingTasks = this._selectedMultipickingTasks.value;
        if (!selectedMultipickingTasks) {
            selectedMultipickingTasks = [];
        }

        selectedMultipickingTasks = selectedMultipickingTasks.filter(
            (x) => x.deliveryTaskID !== maskGroups.deliveryTaskID
        );
        this._selectedMultipickingTasks.next(selectedMultipickingTasks);
    }

    private getTodayTasks(taskGroups: TaskGroup[]) {
        var todaysDate = new Date();
        var todayTaskGroup = taskGroups.filter((x) => {
            return (
                x.deliveryDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0) ||
                true
            );
        });
        return todayTaskGroup;
    }

    private getRefundTasks(taskGroups: TaskGroup[]) {
        var todaysDate = new Date();
        var refundTaskGroup = taskGroups.filter((x) => {
            return (
                x.deliveryDate.setHours(0, 0, 0, 0) != todaysDate.setHours(0, 0, 0, 0) &&
                x.tasks.filter((y) => y.taskType === TaskGroupItemType.RefundingTask)
                    .length
            );
        });
        return refundTaskGroup;
    }

    public getNextTask(): void {
        //If the user does not has any task on them, then we try to find one, depending on the role of the user
        if (!this._todayTaskGroups.value || this._todayTaskGroups.value?.length == 0) {
            this._isNextTaskFindingInProgress.next(true);
            this._geolocationService
                .getCurrentPosition()
                .subscribe((positionResponse) => {
                    if (positionResponse.coords !== undefined) {
                        const supplierPosition = new LatLng(
                            positionResponse.coords.latitude,
                            positionResponse.coords.longitude
                        );
                        this._tasksService
                            .getNextTasksAuto(
                                this._authService.currentUser.userID,
                                supplierPosition
                            )
                            .subscribe(
                                (response) => {
                                    if (response) {
                                        this.getMyTasks();
                                        this._isNextTaskFindingInProgress.next(false);
                                    }
                                },
                                (error) => {
                                    this._isNextTaskFindingInProgress.next(false);
                                    this._flashMessageService.showMessage(
                                        'common.error',
                                        error.error,
                                        5000,
                                        ColorClass.Warning
                                    );
                                }
                            );
                    } else {
                        this._isNextTaskFindingInProgress.next(false);
                    }
                });
        }
    }

    public removeTask(taskID: string) {
        let newTaskGroups = this._taskGroups.getValue();
        let newTodayTaskGroups = this._todayTaskGroups.getValue();

        // ALL tasks
        newTaskGroups.map((taskGroup) => {
            taskGroup.tasks = taskGroup.tasks.filter(
                (x) => x.task.deliveryTaskID !== taskID
            );
            return taskGroup;
        });
        newTaskGroups = newTaskGroups.filter((taskGroup) => taskGroup.tasks.length > 0);

        // TODAY tasks
        newTodayTaskGroups.map((taskGroup) => {
            taskGroup.tasks = taskGroup.tasks.filter(
                (x) => x.task.deliveryTaskID !== taskID
            );
            return taskGroup;
        });

        newTodayTaskGroups = newTodayTaskGroups.filter(
            (taskGroup) => taskGroup.tasks.length > 0
        );

        this._taskGroups.next(newTaskGroups);
        this._todayTaskGroups.next(newTodayTaskGroups);
    }

    public setTaskFailed(taskID: string) {
        this._taskFailedModalService.setFailModalVisible(true);
        this._taskFailedModalService.setTaskID(taskID);
    }

    public showFlashMessage(flashMessage: FlashMessage): void {
        this._flashMessage.next(flashMessage);
        this._hasMessage.next(true);
    }

    public hideFlashMessage(): void {
        this._hasMessage.next(false);
    }

    public setSelectedTask(task: TaskListItem): void {
        this._selectedTask.next(task);
    }

    private generateTaskGroups(
        collectingTasks: CollectingTaskListItem[],
        deliveringTasks: DeliveringTaskListItem[],
        returningTasks: ReturningTaskListItem[],
        refundingTasks: RefundingTaskListItem[],
        containerPlacingTasks: ContainerPlacingTaskListItem[]
    ): Array<TaskGroup> {
        const taskGroups = new Array<TaskGroup>();
        const timestamps = new Array<number>();

        this.addTasksToGroup(
            collectingTasks,
            TaskGroupItemType.CollectingTask,
            taskGroups,
            timestamps
        );
        this.addTasksToGroup(
            deliveringTasks,
            TaskGroupItemType.DeliveringTask,
            taskGroups,
            timestamps
        );
        this.addTasksToGroup(
            refundingTasks,
            TaskGroupItemType.RefundingTask,
            taskGroups,
            timestamps
        );
        this.addTasksToGroup(
            containerPlacingTasks,
            TaskGroupItemType.ContainerPlacingTask,
            taskGroups,
            timestamps
        );

        return taskGroups;
    }

    private getDayDate(deliveryDeadline: Date): Date {
        deliveryDeadline = new Date(deliveryDeadline);
        return new Date(
            deliveryDeadline.getFullYear(),
            deliveryDeadline.getMonth(),
            deliveryDeadline.getDate()
        );
    }

    private addTasksToGroup(
        tasks: Array<
            | CollectingTaskListItem
            | DeliveringTaskListItem
            | RefundingTaskListItem
            | ContainerPlacingTaskListItem
        >,
        taskType: TaskGroupItemType,
        taskGroups: Array<TaskGroup>,
        timestamps: Array<number>
    ): void {
        if (tasks === null) {
            return;
        }
        let taskGroupIndex: number;
        let currentDay: Date;
        let currentTimestamp: number;
        let nextTimestamp: number;
        let taskGroup: TaskGroup;

        tasks.map((task) => {
            currentDay = this.getDayDate(task.estimatedStartDate);
            currentTimestamp = currentDay.getTime();
            taskGroupIndex = timestamps.indexOf(currentTimestamp);

            if (taskGroupIndex < 0) {
                taskGroup = new TaskGroup();
                taskGroup.deliveryDate = currentDay;
                timestamps.push(currentTimestamp);
                timestamps.sort((a, b) => a - b);
                taskGroupIndex = timestamps.indexOf(currentTimestamp);
                taskGroups.splice(taskGroupIndex, 0, taskGroup);
            }

            const taskGroupItem = new TaskGroupItem();
            taskGroupItem.task = task;
            taskGroupItem.taskType = taskType;
            taskGroups[taskGroupIndex].tasks.push(taskGroupItem);

            taskGroups[taskGroupIndex].tasks = taskGroups[taskGroupIndex].tasks.sort(
                (currentTask, nextTask) => {
                    currentTimestamp = moment(currentTask.task.estimatedStartDate).unix();
                    nextTimestamp = moment(nextTask.task.estimatedStartDate).unix();
                    return currentTimestamp - nextTimestamp;
                }
            );
        });
    }

    private setWorkTimeActivity(setWorkTimeActive: boolean): void {
        this._supplierWorktimeActivity.setWorkTimeActive = setWorkTimeActive;
        this._supplierWorktimeActivity.userID = this._authService.currentUser.userID;

        this._geolocationService.getCurrentPosition().subscribe((positionResponse) => {
            if (positionResponse.coords !== undefined) {
                (this._supplierWorktimeActivity.lat = positionResponse.coords.latitude),
                    (this._supplierWorktimeActivity.long =
                        positionResponse.coords.longitude);
            }
            this._suppliersService
                .setWorkTimeActivity(this._supplierWorktimeActivity)
                .subscribe(
                    (response) => {
                        if (response.status === 200) {
                            // do somethings
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        });
    }
}
