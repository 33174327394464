import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';
import {
    ColorClass,
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
    ISupplierWorktimeControlService,
    SUPPLIER_WORKTIME_CONTROL_SERVICE_IMPL,
    SupplierWorktimeControl,
    IMAGE_COMPRESSION_SERVICE_IMPL,
    IImageCompressionService,
    ComletedStartControl,
    FlashMessageService,
    MultiPickingTask,
} from 'shared';
import { TaskFailedModalService } from 'dm-src/app/modules/shared/task-failed-modal/task-failed-modal.service';
import { ModalService } from 'shared';
import { NavigationEnd, Router } from '@angular/router';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { NativeBarcodeReaderService } from 'dm-src/services/native-barcode-reader/native-barcode-reader.service';
import { SumupService } from 'dm-src/services/sumup/sumup.service';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '../../../../../shared/src/lib/types/user-role';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-my-tasks',
    templateUrl: './my-tasks.component.html',
    styleUrls: ['./my-tasks.component.scss'],
})
export class MyTasksComponent implements OnInit, AfterViewInit, OnDestroy {
    public ColorClass = ColorClass;
    public isNativeApplicationWrapper: boolean;
    public isNativeAppTrackingStarted = false;
    public notCompletedStartControl: SupplierWorktimeControl;
    public comletedStartControl: ComletedStartControl = new ComletedStartControl();

    public imgResultAfterCompression: string;
    public photoLoaded: boolean;
    public controlPhotoImageContent: string;
    public controlPhotoImageExtension: string;
    public additionalInfo: string;
    public isWrapping = false;
    public isControlSaveBlocked = false;
    public isLoading = false;
    public isNewWrapper = !!window.ReactNativeWebView;

    public maximumMultipickingTasks: number;
    public multiPickingSelectedTasks: MultiPickingTask[];
    public multiPickingAlreadyInProgress = false;
    private routerSubscription: Subscription;

    constructor(
        @Inject(AUTH_SERVICE_IMPL)
        private _authService: IAuthenticationService,
        public myTasksService: MyTasksService,
        public modalService: ModalService,
        private _taskFailedModalService: TaskFailedModalService,
        private isWrappingService: IsWrappingService,
        private nativeBarcodeReaderService: NativeBarcodeReaderService,
        private _sumUpService: SumupService,
        @Inject(SUPPLIER_WORKTIME_CONTROL_SERVICE_IMPL)
        private _supplierWorktimeControlService: ISupplierWorktimeControlService,
        private _router: Router,
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _isWrappingService: IsWrappingService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        @Inject(IMAGE_COMPRESSION_SERVICE_IMPL)
        private _imageCompressionService: IImageCompressionService,
        private _flashMessageService: FlashMessageService,
        private _translateService: TranslateService
    ) {}

    ngAfterViewInit(): void {
        this.isWrapping = this._isWrappingService.isWrapping();
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService
                .getCurrentPostMessage()
                .subscribe((response) => {
                    if (response.functionName === 'CAMERA_START') {
                        this.setControlPhotoImageContent(
                            'data:image/jpeg;base64,' + response?.result,
                            'jpeg'
                        );
                        this.imgResultAfterCompression =
                            'data:image/jpeg;base64,' + response?.result;
                    } else if (response.functionName === 'GET_LAT_LON') {
                        this._iosAndAndroidNatvieFunctionsService.currentLatLonData.next(
                            response?.result
                        );
                    }
                });
        } else if (this.isWrapping) {
            this.nativeBarcodeReaderService.initBarcodeReading(false);
            this._sumUpService.init();

            setTimeout(() => {
                this._nativeTakePhotoService.init();
            }, 0);

            this._nativeTakePhotoService.getBase64ContentResult().subscribe((result) => {
                if (result) {
                    this.imgResultAfterCompression = result;
                    this.photoLoaded = true;
                }
            });
        }
    }

    ngOnInit() {
        this.isLoading = true;
        this.myTasksService.getMyTasks();

        this.myTasksService.multiPickingAlreadyInProgress.subscribe(
            (multiPickingAlreadyInProgress) => {
                this.multiPickingAlreadyInProgress = multiPickingAlreadyInProgress;
            }
        );

        this.myTasksService.selectedMultipickingTasks.subscribe((selectedTasks) => {
            this.multiPickingSelectedTasks = selectedTasks;
        });

        this.routerSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url == '/my-tasks') {
                    this.myTasksService.getMyTasks();
                    if (
                        this.isWrappingService.isWrapping() ||
                        window.ReactNativeWebView
                    ) {
                        this.myTasksService.startAppGPSTracking();
                    }
                }

                if (event.url == '/logout') {
                    this.resetAll();
                    this.myTasksService.stopAppGPSTracking();
                }
            }
        });

        this.myTasksService.maximumMultipickingTasks.subscribe((value) => {
            this.maximumMultipickingTasks = value;
        });

        this.myTasksService.isNativeApplicationWrapper.subscribe((value) => {
            this.isNativeApplicationWrapper = value;
        });
        this.myTasksService.isNativeAppTrackingStarted.subscribe((value) => {
            this.isNativeAppTrackingStarted = value;
            this.isLoading = false;
        });
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    public onTaskFailSet(status: number) {
        if (status === 200) {
            this.myTasksService.removeTask(this._taskFailedModalService.taskID);
        }
    }

    public confirmWorkTimeStart() {
        const currentUser = this._authService.currentUser;

        if (currentUser.hasRoles([UserRole.Refunder])) {
            this.myTasksService.startAppGPSTracking();
        } else {
            this._supplierWorktimeControlService
                .GetStartControls(currentUser.userID)
                .subscribe((worktimeStartControls) => {
                    if (worktimeStartControls && worktimeStartControls.length > 0) {
                        this.notCompletedStartControl = worktimeStartControls[0];
                        this.modalService.setModalVisibility(
                            true,
                            'start-worktime-control'
                        );
                    } else {
                        this.modalService.setModalVisibility(
                            false,
                            'start-worktime-control'
                        );
                        this.modalService.setModalVisibility(
                            true,
                            'start-worktime-confirm'
                        );
                    }
                });
        }
    }

    public addComletedStartControl() {
        this.isControlSaveBlocked = true;
        this.comletedStartControl.supplierID = this._authService.currentUser?.userID;
        this.comletedStartControl.additionalInfo = this.additionalInfo;
        this.comletedStartControl.photoUrl = this.imgResultAfterCompression;
        this.comletedStartControl.supplierWorktimeControlID =
            this.notCompletedStartControl.supplierWorktimeControlID;

        if (this.IsControlsInvalid()) {
            this._flashMessageService.showMessage(
                this._translateService.instant('common.danger'),
                this._translateService.instant('common.some-validation-is-missing'),
                10000,
                ColorClass.Danger
            );
            this.isControlSaveBlocked = false;
            return;
        }

        this._supplierWorktimeControlService
            .AddComletedStartControl(this.comletedStartControl)
            .subscribe((worktimeStartControls) => {
                this.resetAll();
                this.isControlSaveBlocked = false;

                if (
                    worktimeStartControls &&
                    worktimeStartControls.body &&
                    worktimeStartControls.body.length > 0
                ) {
                    this.notCompletedStartControl = worktimeStartControls.body[0];
                    this.modalService.setModalVisibility(true, 'start-worktime-control');
                } else {
                    this.resetAll();
                    this.myTasksService.getMyTasks();
                    this.modalService.setModalVisibility(false, 'start-worktime-control');
                    this.modalService.setModalVisibility(true, 'start-worktime-confirm');
                }
            });
    }

    public onPhotoInputChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                this._imageCompressionService
                    .compressImage(e.target.result, false, 85)
                    .then((result) => (this.imgResultAfterCompression = result));

                this.photoLoaded = true;
            };

            reader.onloadend = (e) => {
                const imageExtension = fileInput.target.files[0].name
                    .split('.')
                    .pop()
                    .toLowerCase();
                this.setControlPhotoImageContent(
                    reader.result.toString(),
                    imageExtension
                );
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    public setControlPhotoImageContent(
        imageContent: string,
        imageExtension: string
    ): void {
        this.controlPhotoImageContent = imageContent;
        this.controlPhotoImageExtension = imageExtension;
        this.photoLoaded = true;
    }

    public resetAll() {
        this.additionalInfo = '';
        this.deletePhoto();
    }

    public deletePhoto(): void {
        this.controlPhotoImageContent = '';
        this.controlPhotoImageExtension = '';
        this.photoLoaded = false;
        this.imgResultAfterCompression = null;
    }

    handleTakeAPhoto() {
        this._iosAndAndroidNatvieFunctionsService.takeAPicture();
    }

    public IsControlsInvalid(): boolean {
        if (this.isAdditionalInfoInvalid || this.isPhotoInvalid) {
            return true;
        }
        return false;
    }

    public get isPhotoInvalid() {
        return (
            this.notCompletedStartControl.isPhotoRequired &&
            (this.imgResultAfterCompression == '' || !this.imgResultAfterCompression)
        );
    }

    startMultipicking() {
        this.myTasksService.startMultipicking();
    }

    public get isAdditionalInfoInvalid() {
        return (
            this.notCompletedStartControl.isAdditionalInfoRequired &&
            (this.additionalInfo == '' || !this.additionalInfo)
        );
    }
}
