<lib-modal
    [title]="'collecting.invalid-offline-product-title'"
    [componentID]="'invalid-offline-product-modal'"
>
    <p class="text-danger">
        {{ 'collecting.scanned-product-not-list' | translate }}
    </p>
    <p class="font-weight-bold">
        {{ 'collecting.scanned-product' | translate }}
    </p>
    <div class="product-details">
        <table>
            <tr>
                {{
                    offlineProductsService.selectedOfflineProduct?.productName
                }}
                {{
                    'collecting.product' | translate
                }}
            </tr>
            <tr>
                {{
                    'collecting.unit-price' | translate
                }}:
                {{
                    offlineProductsService.selectedOfflineProduct?.markupPrice
                }}
                /
                {{
                    'collecting.' + offlineProductsService.selectedOfflineProduct?.unit
                        | translate
                }}
            </tr>
            <tr>
                {{
                    'common.barcode' | translate
                }}:
                {{
                    (offlineProductsService.selectedOfflineProduct?.gtinList)[0]
                }}
            </tr>
            <tr>
                {{
                    'collecting.unit' | translate
                }}:
                {{
                    'collecting.' + offlineProductsService.selectedOfflineProduct?.unit
                        | translate
                }}
            </tr>
        </table>
    </div>

    <ng-container *ngIf="collectingItemsService.states.multiCollectingStates?.length > 1">
        <h2 class="text-success">
            {{ 'collecting.select-order-to-add-product' | translate }}
        </h2>
        <div
            *ngFor="
                let multiCollectingState of collectingItemsService.states
                    .multiCollectingStates
            "
        >
            <div
                class="btn btn-info"
                style="margin: 2px; border-radius: 15px; border-width: 3px"
                [ngClass]="{
                    'border border-success':
                        multiCollectingState.orderID ===
                        collectingItemsService.states.orderID
                }"
                [ngStyle]="{
                    'background-color':
                        multiCollectingState.multipickingTaskIdentifierColor
                }"
                (click)="
                    collectingItemsService.setDefaultStatedToSelectedMultiCollectingState(
                        multiCollectingState.orderID
                    )
                "
            >
                {{ multiCollectingState.multipickingTaskIdentifierChar }} -
                {{ multiCollectingState.customerName }}
            </div>

            <span
                *ngIf="
                    multiCollectingState.orderID === collectingItemsService.states.orderID
                "
                [ngClass]="{
                    'p-2 font-weight-bold text-success':
                        multiCollectingState.orderID ===
                        collectingItemsService.states.orderID
                }"
            >
                >>> {{ 'collecting.order-selected' | translate }}
            </span>
        </div>
    </ng-container>

    <button class="btn btn-success btn-lg btn-block mt-2" (click)="collect()">
        <span class="font-weight-bold text-smaller">{{
            'collecting.add-cart' | translate
        }}</span>
    </button>
    <lib-btn-close></lib-btn-close>
</lib-modal>
