<app-native-take-photo-result *ngIf="isWrapping"></app-native-take-photo-result>
<app-native-barcode-reader-input *ngIf="isWrapping"></app-native-barcode-reader-input>
<app-sumup-callback-input></app-sumup-callback-input>
<div class="container">
    <ng-template #loading>
        <app-preloader></app-preloader>
    </ng-template>
    <ngb-alert
        *ngIf="myTasksService.hasMessage | async"
        [type]="(myTasksService.flashMessage | async).type"
        (close)="myTasksService.hideFlashMessage()"
    >
        <strong>{{ (myTasksService.flashMessage | async).title }}</strong>
        {{ (myTasksService.flashMessage | async).message }}
    </ngb-alert>
    <div
        class="row"
        *ngIf="
            myTasksService.taskGroups &&
                !(myTasksService.isGettingTasksInProgress | async);
            else loading
        "
    >
        <div
            *ngIf="!isNativeAppTrackingStarted; else tasksVisibleContainer"
            class="text-center w-100"
        >
            <button class="btn btn-success m-2 p-2 w-75" (click)="confirmWorkTimeStart()">
                {{ 'my-tasks.start-worktime-tracking' | translate }}
            </button>
            <hr class="w-75" />
        </div>

        <ng-template #tasksVisibleContainer>
            <div *ngIf="isNativeAppTrackingStarted" class="text-center w-100">
                <button
                    class="btn btn-outline-danger m-2 p-2 w-75"
                    (click)="myTasksService.stopAppGPSTracking()"
                >
                    {{ 'my-tasks.stop-worktime-tracking' | translate }}
                </button>
            </div>

            <div
                *ngIf="isNativeAppTrackingStarted && myTasksService.taskGroups"
                class="text-center w-100"
            >
                <button
                    class="btn btn-outline-success w-75"
                    (click)="myTasksService.getMyTasks(true)"
                >
                    {{ 'common.refresh-tasks' | translate }}
                </button>
                <hr class="w-75" />
            </div>

            <div class="col col-sm-12" *ngIf="!myTasksService.taskGroups">
                No tasks assigned to you.
            </div>

            <ng-container *ngIf="myTasksService.todayTaskGroups | async">
                <div
                    task-group
                    *ngFor="let todayTaskGroup of myTasksService.todayTaskGroups | async"
                    [taskGroup]="todayTaskGroup"
                    [isCollapsed]="false"
                    [hasFrame]="true"
                    class="task-group-column col-12"
                ></div>
            </ng-container>

            <div *ngIf="maximumMultipickingTasks > 1">
                <button
                    class="btn btn-success multi-picking-button"
                    (click)="startMultipicking()"
                >
                    <span *ngIf="!multiPickingAlreadyInProgress">{{
                        'my-tasks.start-multipicking-tasks' | translate
                    }}</span>
                    <span *ngIf="multiPickingAlreadyInProgress">{{
                        'my-tasks.continue-multipicking-tasks' | translate
                    }}</span>
                    &nbsp;&nbsp;
                    <span class="badge badge-primary">
                        {{ maximumMultipickingTasks }}/
                        {{ multiPickingSelectedTasks?.length }}</span
                    >
                </button>
            </div>

            <ng-container
                *ngIf="
                    !(myTasksService.todayTaskGroups | async) ||
                    (myTasksService.todayTaskGroups | async).length == 0
                "
            >
                <button
                    *ngIf="!(myTasksService.isNextTaskFindingInProgress | async)"
                    (click)="myTasksService.getNextTask()"
                    class="btn btn-outline-success m-auto p-2 w-75"
                >
                    {{ 'my-tasks.find-next-task' | translate }}
                </button>
                <button
                    *ngIf="myTasksService.isNextTaskFindingInProgress | async"
                    class="btn btn-outline-info disabled m-auto p-2 w-75"
                >
                    <i
                        class="fa fa-spinner fa-spin loading-icon-custom"
                        aria-hidden="true"
                    ></i>
                    {{ 'my-tasks.find-next-task-in-progress' | translate }}
                    <i
                        class="fa fa-spinner fa-spin loading-icon-custom"
                        aria-hidden="true"
                    ></i>
                </button>
            </ng-container>
        </ng-template>
    </div>
</div>
<app-task-failed-modal (onTaskFailSet)="onTaskFailSet($event)"></app-task-failed-modal>
<app-package-verify-modal></app-package-verify-modal>

<lib-modal
    [title]="'my-tasks.out-of-shop-location-modal-title' | translate"
    [componentID]="'out-of-shop-location-modal'"
>
    <p>
        {{ 'my-tasks.out-of-shop-location-modal-text' | translate }}
    </p>
    <button
        type="button"
        class="btn btn-warning"
        (click)="modalService.setModalVisibility(false, 'out-of-shop-location-modal')"
    >
        OK
    </button>
</lib-modal>
<lib-modal
    [title]="'my-tasks.start-worktime-control-message' | translate"
    [componentID]="'start-worktime-control'"
    [bgColorClass]="'danger'"
    [hasCloseIcon]="false"
>
    <ng-container *ngIf="notCompletedStartControl">
        <div class="w-100">
            <h2 class="text-left">
                {{ notCompletedStartControl.text }}
            </h2>
        </div>

        <div class="photo-thumbnail-container ml-auto mr-auto">
            <img
                class="img-thumbnail d-block mr-auto ml-auto"
                [src]="
                    imgResultAfterCompression ??
                    notCompletedStartControl.photoPlaceholderUrl
                "
            />
        </div>
        <div
            class="file-input"
            *ngIf="!photoLoaded && notCompletedStartControl.isPhotoRequired"
        >
            <input
                *ngIf="!isNewWrapper"
                type="file"
                accept="image/*"
                capture="environment"
                id="photo-input"
                onclick="this.value = null"
                (change)="onPhotoInputChanged($event)"
                hidden
            />
            <label
                class="photo-btn btn btn-block btn-info"
                for="photo-input"
                (click)="handleTakeAPhoto()"
            >
                <i class="fa fa-camera"></i> {{ 'collecting.photo-upload' | translate }}
            </label>
            <div *ngIf="isPhotoInvalid" class="text-danger text-center">
                <div>
                    {{ 'collecting.photo-upload' | translate }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>
        <hr />
        <div
            *ngIf="notCompletedStartControl.isAdditionalInfoRequired"
            class="w-100 mt-4 mb-4"
        >
            <input
                type="text"
                id="additionalInfo"
                [(ngModel)]="additionalInfo"
                class="form-control"
                placeholder="{{ notCompletedStartControl.additionalInfoPlaceholder }}"
                autofocus
            />
            <div *ngIf="isAdditionalInfoInvalid" class="text-danger text-center">
                <div>
                    {{ notCompletedStartControl.additionalInfoPlaceholder }}
                    {{ 'common.is-required' | translate }}
                </div>
            </div>
        </div>

        <div class="text-center w-100">
            <button
                class="btn btn-success w-75 mb-4"
                *ngIf="!isControlSaveBlocked"
                (click)="addComletedStartControl()"
            >
                {{ 'common.save' | translate }}
            </button>
            <button
                class="btn btn-secondary w-75"
                (click)="
                    modalService.setModalVisibility(false, 'start-worktime-confirm');
                    this.resetAll()
                "
            >
                {{ 'common.cancel' | translate }}
            </button>
        </div>
    </ng-container>
</lib-modal>

<lib-modal
    [title]="'my-tasks.are-you-sure-starting-your-work' | translate"
    [componentID]="'start-worktime-confirm'"
    [bgColorClass]="'info'"
>
    <div class="w-100">
        <p class="text-center">
            {{ 'my-tasks.to-start-work-message' | translate }}
        </p>
    </div>
    <div class="text-center w-100">
        <button
            class="btn btn-success w-75 mb-4"
            (click)="myTasksService.startAppGPSTracking()"
        >
            {{ 'common.yes' | translate }}
        </button>
        <button
            class="btn btn-secondary w-75"
            (click)="modalService.setModalVisibility(false, 'start-worktime-confirm')"
        >
            {{ 'common.cancel' | translate }}
        </button>
    </div>
</lib-modal>
