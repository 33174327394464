import { Component, Input } from '@angular/core';
import {
    CollectingTaskListItem,
    MultiPickingTask,
    SendBirdChatChannel,
    DeliveryTaskState,
} from 'shared';
import { environment } from 'dm-src/environments/environment';
import { PaymentType } from 'shared';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { MyTasksService } from 'dm-src/app/modules/my-tasks/my-tasks.service';

@Component({
    selector: '[collecting-task-item]',
    templateUrl: './collecting-task-item.component.html',
    styleUrls: ['./collecting-task-item.component.scss'],
})
export class CollectingTaskItemComponent {
    @Input() task: CollectingTaskListItem;
    public isCollapsed = true;
    public dateFormat: string;
    public customerChat = false;
    public customerChatInfo?: SendBirdChatChannel;
    public maximumMultipickingTasks: number;
    public multiPickingSelectedTasks: MultiPickingTask[];
    public multiPickingTask: MultiPickingTask;
    public isMultiPickingSelectedTask = false;
    public multiPickingAlreadyInProgress = false;

    public PaymentType = PaymentType;

    constructor(
        private _IosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private myTasksService: MyTasksService
    ) {
        this.dateFormat = environment.deliveryDateFormat;
    }

    ngOnInit(): void {
        console.log('this.task', this.task);

        this.multiPickingTask = {
            deliveryTaskID: this.task.deliveryTaskID,
            orderID: this.task.orderID,
            providerName: this.task.providerName,
            multipickingTaskIdentifierChar: this.task.multipickingTaskIdentifierChar,
            multipickingTaskIdentifierColor: this.task.multipickingTaskIdentifierColor,
        };

        this.myTasksService.maximumMultipickingTasks.subscribe((maximumTasks) => {
            this.maximumMultipickingTasks = maximumTasks;
        });

        this.myTasksService.multiPickingAlreadyInProgress.subscribe(
            (multiPickingAlreadyInProgress) => {
                this.multiPickingAlreadyInProgress = multiPickingAlreadyInProgress;
            }
        );

        this.myTasksService.selectedMultipickingTasks.subscribe((selectedTasks) => {
            this.multiPickingSelectedTasks = selectedTasks;
            this.isMultiPickingSelectedTask = selectedTasks.some(
                (task) => task.deliveryTaskID === this.task.deliveryTaskID
            );
        });

        if (this.task?.sendBirdChatChannel?.channelUrl) {
            this.customerChat = true;
            this.customerChatInfo = this.task.sendBirdChatChannel;
        }
    }

    public get isDelivererNotesVisible() {
        return (
            this.task.state === DeliveryTaskState[DeliveryTaskState.ToDo] ||
            this.task.state === DeliveryTaskState[DeliveryTaskState.InProgress]
        );
    }

    openChat(): void {
        this._IosAndAndroidNatvieFunctionsService.openSendbird(this.customerChatInfo);
    }

    changed = (evt) => {
        if (
            this.multiPickingSelectedTasks?.length > 0 &&
            this.multiPickingSelectedTasks[0]?.providerName !==
                this.multiPickingTask.providerName
        ) {
            alert(
                'You can only select tasks from the same provider: ' +
                    this.multiPickingSelectedTasks[0]?.providerName
            );
            return;
        }

        if (
            this.multiPickingSelectedTasks?.length >= this.maximumMultipickingTasks &&
            evt.target.checked
        ) {
            alert('The selectable tasks are maximum ' + this.maximumMultipickingTasks);
            return;
        }

        evt.target.checked
            ? this.myTasksService.selectMultipickingTask(this.multiPickingTask)
            : this.myTasksService.deselectMultipickingTask(this.multiPickingTask);
    };
}
