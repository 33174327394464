import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { OrderRemovedItemReasons } from 'dist/shared/lib/types/order-removed-item-reasons';
import { Observable } from 'rxjs';
import { OrderItem } from 'shared';
import { NativeTakePhotoService } from 'dm-src/services/native-take-photo/native-take-photo.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { CreateSupplierRegion } from '../../../../dtos/create-supplier-region';
import { CollectingStatesService } from '../collecting-states.service';

@Component({
    selector: 'app-order-items-container',
    templateUrl: './order-items-container.component.html',
    styleUrls: ['./order-items-container.component.scss'],
})
export class OrderItemsContainerComponent implements OnInit, AfterViewInit {
    @Input() orderItems: Observable<OrderItem[]>;
    @Input() orderRemovedItemReasons: Observable<OrderRemovedItemReasons[]>;
    @Input() currentTotal: Observable<number>;
    @Input() sumDeposit: Observable<number>;
    @Input() parent: string;
    @Input() name: string;
    @Input() title: string;
    @Input() classes: string;
    @Input() icon: string;

    public isCollapsed = true;
    public isWrapping = false;
    public orderedOrderItems: OrderItem[];
    public originalOrderedOrderItems: OrderItem[];
    public currentMapshelfDisplayNameKey = '';

    constructor(
        private _nativeTakePhotoService: NativeTakePhotoService,
        private _isWrappingService: IsWrappingService,
        private _states: CollectingStatesService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService
    ) {}

    ngOnInit() {
        this.isWrapping = this._isWrappingService.isWrapping();

        this.orderItems.subscribe((orderItems) => {
            this.orderedOrderItems = orderItems?.sort((a, b) => {
                if (a.sequenceOrder === null && b.sequenceOrder === null) {
                    return 0; // Mindkettő null, nem változik a sorrend
                }
                if (a.sequenceOrder === null) {
                    return 1; // A null kerül hátra
                }
                if (b.sequenceOrder === null) {
                    return -1; // A nem null kerül előre
                }
                return a.sequenceOrder - b.sequenceOrder; // Normál számsorrend
            });
            this.originalOrderedOrderItems = this.orderedOrderItems;
        });

        this._states.filteredMultiCollectingByColor?.subscribe((color) => {
            if (color !== '') {
                this.orderedOrderItems = this.originalOrderedOrderItems?.filter(
                    (orderItem) => orderItem?.multipickingTaskIdentifierColor === color
                );
            } else {
                this.orderedOrderItems = this.originalOrderedOrderItems;
            }
        });
    }

    ngAfterViewInit() {
        if (this.isWrapping) {
            setTimeout(() => {
                if (this._isWrappingService.isWrapping()) {
                    this._nativeTakePhotoService.init();
                    this.isWrapping = true;
                }
            }, 0);
        }
    }

    updateCurrentMapshelfDisplayNameKey(nextMapshelfDisplayNameKey: string) {
        if (nextMapshelfDisplayNameKey != '') {
            this.currentMapshelfDisplayNameKey = nextMapshelfDisplayNameKey;
        }
    }
}
